<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="period"
    locale-section="pages.periods"
    field-section="periods"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "PeriodDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: period
        period
        tpq
        taq
        en
        sv
      `,
      inputAttributes: {
        period: { required: true },
        tpq: { type: "number", required: true },
        taq: { type: "number", required: true },
        en: { required: true },
        sv: { required: true }
      }
    };
  }
};
</script>
